import React from 'react';

import Configs from './Configs';
import * as apiCalls from './common/apiCalls';

// ----------------------------------------------------------
// ErrorBoundary
// ----------------------------------------------------------
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false};
  }

  static getDerivedStateFromError(error) {
    return { hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // Log error locally
    console.error("[ErrorBoundary.js][componentDidCatch()] Error: ", error, errorInfo);

    // Log error to a logging service
    apiCalls.logErrorToBackend(process.env.REACT_APP_UNAUTHENTICATED_REQUESTS_API_TOKEN, {
        frontendService: Configs.devEnvironment ? 'frontend_dev' : Configs.stagingEnvironment ? 'frontend_staging' : 'frontend_prod',
        type: "error",
        message: error.message,
        stack: error.stack,
        componentStack: errorInfo.componentStack,
        timestamp: new Date().toISOString(),
        userAgent: navigator.userAgent,
        location: window.location.href
        // correlationID: getCorrelationID(), // Optional, if correlation ID is in use
    });


    // Log error to Amplitude
    // amplitudeInstance.logEvent('error', {error: error, errorInfo: errorInfo});
  }

// ----------------------------------------------------------   
// Listeners
// ----------------------------------------------------------
  componentDidMount() {
        // Capture global errors
        window.onerror = (message, source, lineno, colno, error) => {
            console.error("[ErrorBoundary.js][window.onerror()] Global error caught:", {
                message,
                source,
                lineno,
                colno,
                error,
            });

            // Log error to a logging service
            apiCalls.logErrorToBackend(process.env.REACT_APP_UNAUTHENTICATED_REQUESTS_API_TOKEN, {
                frontendService: Configs.devEnvironment ? 'frontend_dev' : Configs.stagingEnvironment ? 'frontend_staging' : 'frontend_prod',
                type: "error",
                message: message,
                stack: error?.stack,
                componentStack: error?.toString(),
                timestamp: new Date().toISOString(),
                userAgent: navigator.userAgent,
                location: window.location.href,
                lineno: lineno,
                colno: colno,
                source: source,

                // correlationID: getCorrelationID(), // Optional, if correlation ID is in use
            });
        };

        window.onunhandledrejection = (event) => {
            console.error("[ErrorBoundary.js][window.onunhandledrejection()] Unhandled promise rejection:", event.reason);

            // Log error to a logging service
            apiCalls.logErrorToBackend(process.env.REACT_APP_UNAUTHENTICATED_REQUESTS_API_TOKEN, {
                frontendService: Configs.devEnvironment ? 'frontend_dev' : Configs.stagingEnvironment ? 'frontend_staging' : 'frontend_prod',
                type: "error",
                message: event.reason?.toString(),
                stack: event.reason?.toString(),
                componentStack: event.reason?.toString(),
                timestamp: new Date().toISOString(),
                userAgent: navigator.userAgent,
                location: window.location.href
                // correlationID: getCorrelationID(), // Optional, if correlation ID is in use
            });
        }

        // Capture unhandled promise rejections
        window.addEventListener("unhandledrejection", (event) => {
            console.error("[ErrorBoundary.js][unhandledrejection()] Unhandled promise rejection:", event.reason);

            // Log error to a logging service
            apiCalls.logErrorToBackend(process.env.REACT_APP_UNAUTHENTICATED_REQUESTS_API_TOKEN, {
                frontendService: Configs.devEnvironment ? 'frontend_dev' : Configs.stagingEnvironment ? 'frontend_staging' : 'frontend_prod',
                type: "error",
                message: event.reason?.toString(),
                stack: event.reason?.toString(),
                componentStack: event.reason?.toString(),
                timestamp: new Date().toISOString(),
                userAgent: navigator.userAgent,
                location: window.location.href
                // correlationID: getCorrelationID(), // Optional, if correlation ID is in use
            });
        });
    }

    componentWillUnmount() {
        // Cleanup global error handlers
        window.onerror = null;
        window.removeEventListener("unhandledrejection", this.handlePromiseRejection);
    }


    // ----------------------------------------------------------
    // Render
    // ------------------------------------------------
    render() {
        if (this.state.hasError) {
        return <h1>Something went wrong.</h1>;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;