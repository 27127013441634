// React
import React, { createContext, useState, useContext, useEffect } from 'react';

import { v4 as uuidv4 } from 'uuid';
import Configs from '../Configs';

// 3P
// import { useMsal} from "@azure/msal-react";
import {useIsAuthenticated  } from "@azure/msal-react";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  // SSO
  const [isAuthenticationLoading, setIsAuthenticationLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [isGoogleAuthenticated, setIsGoogleAuthenticated] = useState(false);

  // Azure integrations
  // const {instance } = useMsal();
  const [isAzureAuthenticated, setIsAzureAuthenticated] = useState(useIsAuthenticated());

  // Authenticated variables
  const [token, setToken] = useState(null);
  const [employeeID, setEmployeeID] = useState(null);
  const [companyID, setCompanyID] = useState(null);
  const [securityLevel, setSecurityLevel] = useState(null);

  // Session variables (e.g.: correlationId)
  const auxCorrelationId = sessionStorage.getItem('correlationId');
  const [correlationId, setCorrelationId] = useState(auxCorrelationId || null);

  // ------------------------------
  // Effects
  // ------------------------------
  useEffect(() => {
    // Check if there is a correlationId in the session storage
    if (correlationId === null) {
      const newCorrelationId = uuidv4();
      setCorrelationId(newCorrelationId);
      sessionStorage.setItem('correlationId', newCorrelationId);
    }

    let correaltionIdAux = sessionStorage.getItem('correlationId');
    if (Configs.devEnvironment) console.log(`[appContext.js][useEffect()] CorrelationId: ${correaltionIdAux}`);

  }, []);

  // ------------------------------
  // Functions
  // ------------------------------
  async function authLogout() {
    setAuthenticated(false);
    setToken(null);
    setEmployeeID(null);
    setCompanyID(null);
    setSecurityLevel(null);
  }

// ------------------------------
  return (
    <AuthContext.Provider value={{ 
      isAuthenticationLoading, setIsAuthenticationLoading,
      // SSO
      authenticated, setAuthenticated, 
      // Google
      isGoogleAuthenticated, setIsGoogleAuthenticated, 
      // Azure
      isAzureAuthenticated, setIsAzureAuthenticated,
      // Authenticated variables
      token, setToken, employeeID, setEmployeeID, companyID, setCompanyID, securityLevel, setSecurityLevel,
      // CorrelationId
      correlationId,
      // Logout
      authLogout
      }}>
      {children}
    </AuthContext.Provider>
  );
};